<template>
  <div class="row">
    <div class="col-md-12 px-7">
      <div id="filter-card" class="row py-3 px-4">
        <form class="w-100" @submit.prevent="filterResult">
          <div class="col-lg-12 col-xl-12 px-0" v-if="filters">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-6 col-md-6 col-sm-12 my-2 row px-0 mx-0 d-flex justify-content-start items-center">
                <input-filter
                    :helper-text="$t('general.search')"
                    :model.sync="filters.search"
                    :placeholder="$t('general.search_filter')"
                    title="general.search"
                ></input-filter>
              </div>
              <div class="col-lg-5 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      :model.sync="filters.start_date"
                      name="start_time"
                      title="general.start_date">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      :model.sync="filters.end_date"
                      name="end_date"
                      title="general.end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                          @click="filterResult(null)">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                          @click="resetFilters">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row px-10 py-4">
            <h2 v-if="false" class="text-center py-10 col-12">
              {{ $t('sample.you_have_no_task') }}
            </h2>
            <template v-if="tasks.count > 0 || waybills.length > 0">
              <div class="row">
                <InvoiceTaskCard
                    :task="tasks"
                    :type="1"
                    @click="onClickCard"
                ></InvoiceTaskCard>

                <InvoiceTaskCard
                    :task="overheadTasks"
                    :type="4"
                    @click="onClickCard"
                ></InvoiceTaskCard>

                

                <!-- <InvoiceTaskCard
                    :task="packing_list"
                    :type="3"
                    @click="onClickCard"
                ></InvoiceTaskCard>

                <InvoiceTaskCard
                    :task="waybills"
                    :type="2"
                    @click="onClickCard"
                ></InvoiceTaskCard> -->
              </div>
              <div class="ml-6 mt-4" v-if="canCheckAccountingAndAdminRole">
                <div class="card-title">
                  <h2 class="card-label w-auto overhead-invoice-title">{{ $t('Maaş ve SSK Gideri') }}</h2>
                </div>
                <div style="display:flex">
                  <custom-multi-select
                      @save="getInputData($event,index)"
                      :is-inline="false"
                      :item-per-row="1"
                      :max="1"
                      :model.sync="monthly"
                      :not-list="true"
                      :options="convertArrayToObjectByKey(months, 'id', 'name')"
                      :required="true"
                      :title="$t('report.monthly') + ':'"
                      name="month"
                  ></custom-multi-select>
                  <custom-multi-select
                      @save="getInputData($event,index)"
                      :is-inline="false"
                      :item-per-row="1"
                      :max="1"
                      :model.sync="yearly"
                      :not-list="true"
                      :options="yearOptions"
                      :required="true"
                      :title="$t('report.yearly') + ':'"
                      name="year"
                      class="ml-4"
                  ></custom-multi-select>
                   
                </div>
                <text-input :disabled="false" :model.sync="profit_loss_salary_expense"
                  :title="$t('Maaş Gideri')"></text-input>
                <text-input :disabled="false" :model.sync="profit_loss_ssk_expense"
                  :title="$t('SSK Gideri')"></text-input>
                  <button class="btn btn-sm btn-outline-info p-2 btn-pill font-weight-bold cursor-pointer mr-5"
                    @click="updateProfitLossExpense()">
                    {{ $t('general.save') }}
                  </button>
              </div> 
            </template>
            <template v-else>
              <div class="w-100 mt-5">
                <h3 class="text-center">{{ $t('invoice.no_task') }}</h3>
              </div>
            </template>

          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex'
import TaskIndexCard from "@/view/pages/samples/layout/task/TaskIndexCard";
import InputFilter from "@/assets/components/filters/InputFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import Paginate from "@/assets/components/dataTable/Pagination";
import moment from "moment";
import {GET_ITEMS, LOADING, SET_ITEMS} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT} from "@/core/config/constant";
import InvoiceTaskCard from "@/view/pages/invoice/layouts/InvoiceTaskCard";
import * as _ from "lodash";
import { FILTER_TASK, SET_FILTER_TASK,CREATE_ITEM} from "@/core/services/store/invoice/incoming_invoice.module";
import TextInput from "@/assets/components/inputs/TextInput"; 
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

export default {
  name: "InvoiceTasks",
  components: {
    InvoiceTaskCard,
    DashboardBox,
    TaskIndexCard,
    InputFilter,
    DateFilter,
    Paginate,
    TextInput,
    CustomMultiSelect
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER_TASK,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      }
    },   
    yearOptions() {
      const currentYear = new Date().getFullYear();
      const years = [];
      const yearsToGenerate = 5;

      for (let i = 0; i < yearsToGenerate; i++) {
        const year = currentYear - i;
        years.push({ id: year, name: year });
      }
      return this.convertArrayToObjectByKey(years, "id", "name"); 
    },
    canCheckAccountingAndAdminRole(){
      let user = this.authUser;
      if(user && user.roles != null &&  user.roles.length > 0){
        if( this.role_ids.includes(user.roles[0].id) ){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    },
  },
  data() {
    return {
      tasks: [],
      overheadTasks: [],
      waybills: [],
      profit_loss_salary_expense:0,
      profit_loss_ssk_expense:0,
      months: [
        {
          id: "01",
          name: this.$t("dateTime.january_full"),
        },
        {
          id: "02",
          name: this.$t("dateTime.february_full"),
        },
        {
          id: "03",
          name: this.$t("dateTime.march_full"),
        },
        {
          id: "04",
          name: this.$t("dateTime.april_full"),
        },
        {
          id: "05",
          name: this.$t("dateTime.may_full"),
        },
        {
          id: "06",
          name: this.$t("dateTime.june_full"),
        },
        {
          id: "07",
          name: this.$t("dateTime.july_full"),
        },
        {
          id: "08",
          name: this.$t("dateTime.august_full"),
        },
        {
          id: "09",
          name: this.$t("dateTime.september_full"),
        },
        {
          id: "10",
          name: this.$t("dateTime.october_full"),
        },
        {
          id: "11",
          name: this.$t("dateTime.november_full"),
        },
        {
          id: "12",
          name: this.$t("dateTime.december_full"),
        },
      ], 
      monthly: null,
      yearly: null,
      role_ids: []
    };
  },
  methods: {
    ...mapMutations({
      'setFilter': SET_FILTER_TASK,
    }),
    resetFilters() {
      this.setFilter({
        start_date: moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT),
        end_date: moment().add(1, 'd').format(LARAVEL_DATE_FORMAT),
        per_page: 999,
        search: null,
        invoice_task:true
      })
    },
   async filterResult() {
      let self = this;

      let filters = _.cloneDeep(this.filters);
      this.$set(filters, 'start_date', moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'end_date', moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT));
      this.$set(filters, 'invoice_task', true);

      this.$store.dispatch(GET_ITEMS, {
        url: 'api/invoice-count',
        acceptPromise: true,
        filters: filters,
        showLoading: true,
      }).then((result) => {
        if (result.status) {
          self.tasks = {
            count: result.data.count,
            //item: result.data.item, Hızlandırma işlemleri için devre dışı bırakıldı
          }

          self.overheadTasks = {
            count: result.data.overhead_invoice_count,
          }

          let tmpWaybills = [];
          result.data.waybills.forEach(waybill => {
            let orderNumbers = _.uniq(_.map(waybill.transactions, 'waybillable.order_number'));
            let instructionTypes = _.uniq(_.map(waybill.transactions, 'instruction_type.translations[0].name'));
            waybill.order_number = orderNumbers.join(",");
            waybill.instruction_type = instructionTypes.join(",");
            tmpWaybills.push(waybill);
          });

          self.waybills = tmpWaybills;
          let results = [];
          let items = results.data;
          items.forEach(item => {
            let inPackage = 0;
            let boxQuantity = 0;
            let amount = 0;
            let totalAmount = 0;
            item.data.forEach(subItem => {
              inPackage += Number(subItem.in_package) || 0;
              boxQuantity += Number(subItem.box_quantity) || 0;
              amount += Number(subItem.amount) | 0;
              totalAmount +=  Number(subItem.box_quantity) * Number(subItem.in_package) + Number(subItem.broken_parcel)
            })

            results.push({
              id: item.id,
              created_at: item.created_at,
              order_number: item.orderable.order_number,
              in_package: inPackage,
              box_quantity: boxQuantity,
              total_amount: totalAmount,
              amount: amount,
            });
          })
          
        }
      })
      
    },
    onClickCard(payload) {
      if (+payload.type === 1) {
        this.$router.push({name: 'invoice.task.action', params: {id: '1',type:0}})
      }
      if (+payload.type === 4) {
        this.$router.push({name: 'invoice.task.action', params: {id: '1',type:1}})
      }
      if (+payload.type === 2) {
        this.$router.push({name: 'invoice.outgoing'})
      }
      if (+payload.type === 3) {
        this.$router.push({
          name: 'invoice.add_outgoing',
          query: {packing_order_number: payload.task.order_number, packing_id: payload.id}
        })
      }
    },
    getInputData(payload,index) { 
        let self = this;        
        const date = new Date(); 
        let month = (payload && payload.name=='month') ? payload.newValue : date.getMonth() + 1;
        let year = (payload && payload.name=='year') ? payload.newValue : date.getFullYear();

        if(payload){
          let old =  payload.oldValue;
          if(( payload.name=='month') ) {
            month = payload.newValue ;
            year = this.yearly ?? year;
          }else{
            year =   payload.newValue ;
            month = this.monthly ?? month ;
          }
        } 

        let url =`api/report/expense-detail?month=${this.month ?? month}&year=${this.year ?? year}`;
        this.$store.dispatch(GET_ITEMS, {
            url: url,
            acceptPromise: true,
            returnType: 'stateless',
        }).then((result) => {
          if (result.status) {
            let response = result.data; 
            this.profit_loss_salary_expense = response.salary ?? 0;
            this.profit_loss_ssk_expense = response.ssk ?? 0;
            this.monthly = response.month ?? month;
            this.yearly = response.year ?? year;
            this.role_ids = response.role_ids
          }
        });
    },
    updateProfitLossExpense(){
      let self = this; 
      let payload = {
        url:'api/report/expense-save', 
        contents: {
          total_ssk_expense: this.profit_loss_ssk_expense,
          total_salary_expense: this.profit_loss_salary_expense,
          month:typeof this.monthly === 'object' ? this.monthly[0] :this.monthly ,
          year:typeof this.yearly === 'object' ? this.yearly[0] :this.yearly ,
        }
      }
      this.$store.dispatch(CREATE_ITEM, payload).then((result) => {
        if(result.status==true){
          self.sweetAlertSuccess(self.$t('general.successfully_updated'));
        }else{
          self.sweetAlertError(result.data.response.data.message);
        }
      });
    },
  },
  created(){
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.getInputData();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('invoice.invoices'), route: "invoice.index"},
      {title: this.$t('invoice.tasks')}
    ]);

    if (!this.filter) {
      this.resetFilters();
    }

    this.filterResult();
  },
}
</script>

<style scoped>

</style>
<style scoped type="scss">
.card-with {
  width: 300px !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.overhead-invoice-title{
  font-size:1.200rem !important;
}
.card-header {
  padding: 2rem 2.25rem 0 2.25rem;
  background-color: #ffffff;
  border-bottom: 0px;
}

.card.card-custom>.card-body {
  padding: 0rem 2.25rem 2rem 2.25rem;
}

.card-custom2 {
  border-radius: 20px !important;
  border: 3px solid #ffffff !important;
  box-shadow: 0.5px 1px $primary-gray !important;
}
</style>